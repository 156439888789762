import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import LayoutNo from "../../components/LayoutNo"
import HeaderNo from '../../components/HeaderNo'
import Seo from "../../components/Seo"

import ChartArea from '../../images/icons/chart-area.svg'
import Cubes from '../../images/icons/cubes.svg'
import ShippingFast from '../../images/icons/shipping-fast.svg'
import ProjectDiagram from '../../images/icons/project-diagram.svg'
import Tools from '../../images/icons/tools.svg'
import ChartPie from '../../images/icons/chart-pie.svg'
import Sliders from '../../images/icons/sliders.svg'
import Robot from '../../images/icons/robot.svg'
import BusinessTime from '../../images/icons/business-time.svg'
import Recycle from '../../images/icons/recycle.svg'
import Seedling from '../../images/icons/seedling.svg'
import Globe from '../../images/icons/globe.svg'
import Sirkel from '../../images/icons/sirkel.svg'
import BezierCurve from '../../images/icons/bezier-curve.svg'
import RTLogo from '../../images/rt-logo.svg'
import ContactLauriNo from "../../components/ContactLauriNo"


const OurStory = () => {
  return (
    <LayoutNo>

      <Seo title="Vår historie" description="Welement er en partner som hjelper entreprenører, ingeniører og utviklere med å videreutvikle byggenæringen" lang="no" />
      <HeaderNo />

      <div className="container-base">
        <div className="section-1-2-center">
          <h1 className="title-xl wel-mb-s">Vår historie</h1>
          <p className="body-l">Hvorfor vi startet og hvordan vi kan hjelpe til med å innovere byggenæringen.</p>
        </div>
      </div>

      <div className="wel-mb-l">
        <StaticImage
          src="../../images/our-story/team.png"
          quality={100}
          formats={["auto", "webp", "avif"]}
          alt="Welement Team"
          loading="eager"
          placeholder="blurred"
          className="w-full"
        />
      </div>

      <div className="container-base">
        <div className="wel-mb-l">
          <div className="section-1-2-center">
            <img src={ChartArea} alt="Icon" className="h-20 mx-auto wel-mb-xs mr-4 inline-block" />
            <img src={Cubes} alt="Icon" className="h-20 mx-auto wel-mb-xs inline-block" />
            <h3 className="title-m wel-mb-s">Sammenhengen</h3>
            <p className="body-l">Byggeindustrien har vært stillestående og har ikke klart å omfavne innovasjone på lenge. Megatrender som urbanisering og befolkningsvekst presser etterspørselen, og bransjen sliter samtidig med å finne riktige metoder og god arbeidskraft.</p>
          </div>
        </div>

        <div className="wel-mb-l">
          <div className="section-1-2-center">
            <img src={ShippingFast} alt="Icon" className="h-20 mx-auto wel-mb-xs mr-4 inline-block" />
            <img src={ProjectDiagram} alt="Icon" className="h-20 mx-auto wel-mb-xs inline-block" />
            <h3 className="title-m wel-mb-s">Starten</h3>
            <p className="body-l">For ett par år siden spurte vi oss selv; hva vi som byggspesialist faktisk kan gjøre for å forbedre prosessen?</p>
          </div>
        </div>

        <div className="wel-mb-l">
          <div className="section-1-2-center">
            <img src={Tools} alt="Icon" className="h-20 mx-auto wel-mb-xs mr-4 inline-block" />
            <img src={ChartPie} alt="Icon" className="h-20 mx-auto wel-mb-xs inline-block" />
            <h3 className="title-m wel-mb-s">Hvoran kan vi løse det?</h3>
            <p className="body-l mb-8">Vi visste at vi ikke kan forvente nye eller forskjellige resultater dersom vi gjør det samme om og om igjen. Og vi visste også at byggeprosessen ikke er for komplisert. Til slutt koker det ned til at å redusere kostnaden krafitig for materialer og arbeider ofte gir deg en høyere kostnad til slutt.</p>
          </div>
        </div>
      </div>

      <div className="bg-welement-pink">


        <div className="container-base">
          <div className="section-1-2-center wel-mb-l">
            <h2 className="title-l wel-mb-m">Vår løsning</h2>
            <p className="body-l mb-8">Det eneste vi faktisk kan effektivisere, er tiden. Vi spesifisere hvordan vi bruker tiden og reduserer aktiviteter som ikke gir økt verdi.</p>
          </div>
          <div className="flex justify-start wel-mb-l">
            <div className="w-full md:w-2/3 lg:w-1/2 text-left bg-welement-white p-16 rounded">
              <div className="wel-mb-s">
                <img src={Sliders} alt="Icon" className="h-16 mx-auto wel-mb-xs mr-4 inline-block" />
                <img src={Robot} alt="Icon" className="h-16 mx-auto wel-mb-xs mr-4 inline-block" />
                <img src={BusinessTime} alt="Icon" className="h-16 mx-auto wel-mb-xs inline-block" />
              </div>

              <h2 className="title-xs mb-2">Ta konstruksjon utenfor stedet</h2>
              <p className="body-l">Vi tror den mest effektive måten å forbedre byggebransjen og produktiviteten på, er å ta så mye av arbeidet som mulig til kontrollerte fabrikkforhold innendørs der det er mulig å øke automatiseringsnivået og effektivt håndtere sikkerhet, avfall og kvalitet. Dette ved å bruke testede prinsipper for Lean Produksjon. Ved å bruke avansert datastyrt maskineri og å samle sanntidsdata i hvert trinn, kan vi gjøre det mulig for spesialisten vår å bruke den tilgjengelige tiden så produktivt som mulig, med minimalt forbruk av ventetid og fysisk energi.</p>
            </div>
          </div>
          <div className="flex justify-end wel-mb-l">
            <div className="w-full md:w-2/3 lg:w-1/2 text-left bg-welement-white p-16 rounded">
              <div className="wel-mb-s">
                <img src={Recycle} alt="Icon" className="h-16 mx-auto wel-mb-xs mr-4 inline-block" />
                <img src={Seedling} alt="Icon" className="h-16 mx-auto wel-mb-xs mr-4 inline-block" />
                <img src={Globe} alt="Icon" className="h-16 mx-auto wel-mb-xs inline-block" />
              </div>
              <h2 className="title-xs mb-2">Tre er fremtiden</h2>
              <p className="body-l">I tillegg til prosessendringen, mener vi at fremtidens bygninger bør produseres av bærekraftige materialer og trevirke egner seg perfekt til dette. Trevirke er fortsatt fremtidens konstruksjonsmateriale og er ideelt for prefabrikerte elementer som bygningsløsninger, samt for å redusere konstruksjonenes karbonavtrykk.</p>
            </div>
          </div>

          <div className="flex justify-start wel-mb-l">
            <div className="w-full md:w-2/3 lg:w-1/2 text-left bg-welement-white p-16 rounded">
              <div className="wel-mb-s">
                <img src={BezierCurve} alt="Icon" className="h-16 mx-auto wel-mb-xs mr-4 inline-block" />
                <img src={Sirkel} alt="Icon" className="h-16 mx-auto wel-mb-xs inline-block" />
              </div>
              <h2 className="title-xs mb-2">Integrert produksjonsrørledning</h2>
              <p className="body-l">Ved å utnytte den nyeste teknologien og IT-systemene, kombinert med vår kunnskap som totalentreprenør og om anleggsteknikk, sikrer vi oss å skape den mest avanserte fabrikkproduksjonen for å tilby våre partnere verdi uten kompromisser.</p>
            </div>
          </div>
        </div>

      </div>

      <div className="container-base">
        <div className="section-1-2-center">
          <img src={RTLogo} alt="Rand &amp; Tuulberg" className="h-10 mx-auto mb-8 mt-8" />

          <p className="body-l wel-mb-m">Welement er en del av Estiske Rand og Tuulberg Group som har over 23 års erfaring innen byggebransjen, eiendomsutvikling og produksjon av byggningsmateriell.</p>

        </div>
      </div>

      <ContactLauriNo />

    </LayoutNo>
  )

};

export default OurStory;
